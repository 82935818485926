<template>
    <div class="editor">

        <small>{{name}}</small>
        <small>
          <b-badge
            style="margin-left: 16px; cursor: pointer"
            @click="showEditor=!showEditor">
            tools
          </b-badge>
        </small>
        
        <editor-menu-bar v-if="showEditor" :editor="editor" v-slot="{ commands, isActive }">
          <div>
                <button
                  class="menubar__button"
                  @click="showImagePrompt(commands.image)"
                >
                  <icon name="image" />
                </button>

                <button
                class="menubar__button"
                :class="{ 'is-active': isActive.bold() }"
                @click="commands.bold"
                >
                <icon name="bold" />
                </button>

                <button
                class="menubar__button"
                :class="{ 'is-active': isActive.italic() }"
                @click="commands.italic"
                >
                <icon name="italic" />
                </button>

                <button
                class="menubar__button"
                :class="{ 'is-active': isActive.strike() }"
                @click="commands.strike"
                >
                <icon name="strike" />
                </button>

                <button
                class="menubar__button"
                :class="{ 'is-active': isActive.underline() }"
                @click="commands.underline"
                >
                <icon name="underline" />
                </button>

                <button
                class="menubar__button"
                :class="{ 'is-active': isActive.code() }"
                @click="commands.code"
                >
                <icon name="code" />
                </button>

                <button
                class="menubar__button"
                :class="{ 'is-active': isActive.paragraph() }"
                @click="commands.paragraph"
                >
                <icon name="paragraph" />
                </button>

                <button
                class="menubar__button"
                :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                @click="commands.heading({ level: 1 })"
                style='font-size: 15px; vertical-align:top; padding:0 8px;'
                >
                H1
                </button>

                <button
                class="menubar__button"
                :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                @click="commands.heading({ level: 2 })"
                style='font-size: 15px; vertical-align:top; padding:0 8px;'
                >
                H2
                </button>

                <button
                class="menubar__button"
                :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                @click="commands.heading({ level: 3 })"
                style='font-size: 15px; vertical-align:top; padding:0 8px;'
                >
                H3
                </button>

                <button
                class="menubar__button"
                :class="{ 'is-active': isActive.bullet_list() }"
                @click="commands.bullet_list"
                >
                <icon name="ul" />
                </button>

                <button
                class="menubar__button"
                :class="{ 'is-active': isActive.ordered_list() }"
                @click="commands.ordered_list"
                >
                <icon name="ol" />
                </button>

                <button
                class="menubar__button"
                :class="{ 'is-active': isActive.blockquote() }"
                @click="commands.blockquote"
                >
                <icon name="quote" />
                </button>

                <button
                class="menubar__button"
                :class="{ 'is-active': isActive.code_block() }"
                @click="commands.code_block"
                >
                <icon name="code" />
                </button>

                <button
                class="menubar__button"
                @click="commands.horizontal_rule"
                >
                <icon name="hr" />
                </button>

                <button
                class="menubar__button"
                @click="commands.undo"
                >
                <icon name="undo" />
                </button>

                <button
                class="menubar__button"
                @click="commands.redo"
                >
                <icon name="redo" />
                </button>

            </div>
        </editor-menu-bar>
        
        <editor-content class="editor__content" :editor="editor" />
        
        <hr>

    </div>
</template>

<script>
import defaultEditor from '../helpers/defaultEditor';
import Icon from '../components/Icon'
import { EditorContent, EditorMenuBar } from 'tiptap'

export default {
    props: {
      name: String,
      input: Object,
    },
    components: {
      EditorContent,
      EditorMenuBar,
      Icon,
    },
    computed: {},
    data() {
      return {
        editor: defaultEditor(this.input, ({ getJSON }) => this.$emit('output', getJSON())),
        showEditor: false
      }
    },
    methods: {
      showImagePrompt(command) {
        const src = prompt('Enter the url of your image here')
        if (src !== null) {
          command({ src })
        }
      },
    },
    watch : {
      input:function(val) {
        this.editor.setContent(val, true)
      },
    },
    created() {}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variables.scss";

*{
  text-align:left
}

.actions {
  max-width: 30rem;
  margin: 0 auto 2rem auto;
}
.export {
  max-width: 30rem;
  margin: 0 auto 2rem auto;
  pre {
    padding: 1rem;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: bold;
    background: rgba($color-black, 0.05);
    color: rgba($color-black, 0.8);
  }
  code {
    display: block;
    white-space: pre-wrap;
  }
}
.scroll {
    max-height: 100px;
    overflow-y: auto;
}
</style>