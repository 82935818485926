/* eslint-disable no-console */
import { functions } from '../plugins/firebase'

const registerExamUserCallable = functions.httpsCallable('registerExamUser')

export function registerExamUser(email, iid, aids, exids){
  return registerExamUserCallable({
    'src': 'analitica',
    'email': email,
    'aids': aids,
    'iids': iid,
    'exids': exids,
  }).catch((e)=>{
    console.log(e)
    alert(e)
  });
}
export default {
  registerExamUser,
}