<template>
  <div class="home">
    <b-container>
      <b-list-group>

        <b-list-group-item active class="align-items-start">
          <h5>Exercise List</h5>
        </b-list-group-item>

        <b-list-group-item
          v-for="exercise in exercises"
          :key="exercise.id"
          @click="showExerciseModal(exercise)"
          class="align-items-start"
          button>
          <h5>{{ exercise.title }}</h5>
          <!-- <small class="two-line">
              {{ exercise.description }}
          </small>
          <small class="small text-muted one-line">
            tags: <span v-for="tag in exercise.tags" :key="tag">{{tag}}, </span>
          </small> -->
        </b-list-group-item>

      </b-list-group>

      <b-modal
        id="modal-exercise"
        ref="modal"
        :title="`${form.title} ( ${exerciseId} )`"
        @hidden="hideExerciseModal"
        hide-footer
        size="xl"
      >
        <b-overlay :show="form.loading" rounded="sm">
          <b-row>
            <b-col>
              ppid: {{form.ppid}}
              <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group
                  :state="titleState"
                  label="Title"
                  label-for="title-input"
                  invalid-feedback="Title is required"
                >
                  <b-form-input
                    id="title-input"
                    v-model="form.title"
                    :state="titleState"
                    placeholder="Title"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Description"
                  label-for="description-input"
                  invalid-feedback="Name is required"
                >
                  <b-form-textarea
                    id="description-input"
                    v-model="form.description"
                    placeholder="Description"
                    rows="3"
                    max-rows="8"
                  ></b-form-textarea>
                </b-form-group>
              </form>

              <p>Tags</p>
              <b-card
                style="position:relative; height:200px; overflow-y:scroll;"
              >
                <draggable
                  v-model="form.tags"
                  group="tags"
                  @start="drag=true"
                  @end="drag=false">
                  <div v-for="(tag, i) in form.tags" :key="tag">
                    <b-badge style="cursor: pointer;">{{tag}}</b-badge>
                    <b-icon-x @click="form.tags.splice(i,1)"></b-icon-x>
                  </div>
                </draggable>
                <b-form inline @submit.stop.prevent="handleSubmit">
                  <b-input
                    id="newTag-input"
                    v-model="form.newTag"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="new tag"
                  ></b-input>
                  <b-button variant="primary" @click="addTag">add</b-button>
                </b-form>
                <br>
              </b-card>
            </b-col>
            <b-col>
              <b-list-group>
                <b-list-group-item variant="primary">Susunan soal</b-list-group-item>
                <b-list-group-item v-for="item in form.detail_list" :key="item.tag">
                  {{ item.tag }} : {{ item.detail }}
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-overlay>
        <br>
          
        <div class="w-100">
          <b-button
            size="sm"
            @click="resetForm"
          >
            Reset
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="updateExercise"
          >
            Update
          </b-button>
        </div>
      </b-modal>
      
    </b-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import draggable from 'vuedraggable'

export default {
  name: 'home',
  components: {
      draggable,
  },
  computed: {
    ...mapState(['exercises']),
  },
  data() {
    return {
      exerciseId: null,
      titleState: null,
      form: {
        ppid: "",
        loading: false,
        title: '',
        description: '',
        tags: [],
        newTag: "",
        details: {},
        detail_list: [],
        detailMap: {}
      }
    }
  },
  watch : {
    'form.details':function(val) {
      let root = Object.entries(val).find(x=>x[1].root)[0]
      let tags = this.getTopDetail(val, root)
      let counter = 0
      this.form.detail_list = tags.map((tag)=>{
        let detail = {
          tag: tag,
          detail: `${counter+1} - ${counter+val[tag].total}`
        }
        counter+=val[tag].total
        return detail
      })
    },
  },
  methods: {
    ...mapActions([ 'setExercises' ]),
    getTopDetail(details, tag){
      let result = []
      if(details[tag].tags && details[tag].tags.length > 0){
        for( let subTag of details[tag].tags){
          result = result.concat(this.getTopDetail(details, subTag))
        }
      } else {
        result.push(tag)
      }
      return result
    },
    showExerciseModal(exercise){
      this.exerciseId = exercise.id
      this.form.title = exercise.title
      this.form.description = exercise.description
      this.form.ppid = exercise.ppid
      this.form.tags = [...exercise.tags]
      this.form.details = exercise.result.details
      // this.form.detailsMap = this.mapExerciseDetail(exercise.result.details, exercise.result.details.find(d => d.root))
      this.$bvModal.show('modal-exercise')
    },
    hideExerciseModal() {
      this.exerciseId = null
      this.form.title = ''
      this.form.description = ''
      this.form.ppid = ''
      this.form.tags = []
      this.titleState = null
    },
    mapExerciseDetail(details, root){
      let result = {}
      for(let tag in root.tags){
        result.push(this.mapExerciseDetail(details, details[tag]))
      }
      return result
    },
    resetForm() {
      let exercise = this.exercises.find(e => e.id == this.exerciseId)
      this.form.title = exercise.title
      this.form.description = exercise.description
      this.form.ppid = exercise.ppid
      this.form.details = exercise.result.details
      this.form.tags = [...exercise.tags]
    },
    addTag(){
      this.form.tags.push(this.form.newTag)
      this.form.newTag = ""
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.titleState = valid
      return valid
    },
    updateExercise() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      // copy original exercise and edit with form inputs
      let exercise = this.exercises.find(e => e.id == this.exerciseId)
      exercise.title = this.form.title
      exercise.description = this.form.description
      exercise.tags = this.form.tags

      // loading start
      this.form.loading = true

      // Push exercise
      this.setExercises(exercise)
        .then(()=>{
          // loading done
          this.form.loading = false

          // Hide the modal and show success notification
          this.$nextTick(() => {
            this.$bvModal.hide('modal-exercise')
            this.$bvModal.msgBoxOk('Data was updated successfully', {
              title: 'Success',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'success',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
          })
        })
        .catch( error=>{
          // loading done
          this.form.loading = false

          alert(error.code+" : "+error.message)
        })
    }
  }
}
</script>


<style scoped>

.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.two-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

</style>