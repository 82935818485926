import '@babel/polyfill'
import 'mutationobserver-shim'

import Vue from 'vue'

import './assets/sass/main.css'
import './plugins/bootstrap-vue'
import './plugins/vue-fire'
// import './plugins/firebase'
// import './plugins/firebase-admin'
import './plugins/svg-sprite-loader'
import './plugins/perfect-scrollbar'
import firebase from '@/plugins/firebase'

import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  created () {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        store.dispatch('autoSignIn', firebaseUser)
      }
    })
  }
}).$mount('#app')