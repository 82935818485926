<template>
  <div class="home">
    <b-container>
      <b-jumbotron header="Analitica" lead="Welcome to Analitica.id"/>
      <b-form>
        <b-form-group
          horizontal
          :label-cols="4"
          id="input-group-1"
          label="Email address:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.email"
            type="email"
            required
            placeholder="Enter email"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          horizontal
          :label-cols="4"
          id="input-group-2"
          label="Password:"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="form.password"
            required
            placeholder="Enter Password"
            type="password"
          ></b-form-input>
        </b-form-group>

        <b-button  variant="danger" type="logout" @click="onLogOut" v-if="loggedin">Log Out</b-button>
        <b-button variant="primary" type="login" @click="onLogIn" v-else>login</b-button>
        
      </b-form>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
// @ is an alias to /src
export default {
  name: 'home',
  computed: {
    ...mapState(['credentials', 'loggedin']),
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
      }
    }
  },
  methods: {
    ...mapMutations([
      'updateCredentialEmail','updateCredentialPassword'
    ]),
    ...mapActions([
      'login', 'logout', 'checkLoginStatus'
    ]),
    async onLogIn(evt) {
      evt.preventDefault()
      await this.login(this.form)
      if(this.loggedin){
        alert("Login Succes, Welcome.")
      }
    },
    async onLogOut(evt) {
      evt.preventDefault()
      await this.logout()
      if(!this.loggedin){
        alert("Logout Success.")
      }
    }
  }
}
</script>
