<template>
  <b-container>

    <b-row
      id="row-main-nav"
      align-h="between"
    >
      <b-col>
        <b-form-input v-model="email" placeholder="Masukkan Email"></b-form-input>
      </b-col>
      <b-col>
        <b-form-select v-model="premiumSelected" :options="premiumOptions"></b-form-select>
      </b-col>
      <b-col v-if="premiumSelected">
        <b-button @click="submit">add premium</b-button>
      </b-col>
    </b-row>
    
    <hr>

    <div v-if="premiumUser">
      <p>total user: {{Object.entries(premiumUser.users).length}}</p>
      <b-list-group>
        <b-list-group-item
          v-for="[uid, user] in Object.entries(premiumUser.users)"
          :key="uid">
          {{ user.email }}
          ( {{ user.dateRegister.toDate().toLocaleDateString('id-ID', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }} )
        </b-list-group-item>
      </b-list-group>
    </div>

  </b-container>
</template>

<script>
/* eslint-disable no-console */

import { mapState, mapActions } from 'vuex'
import { registerExamUser } from '../helpers/call'

export default {
  data() {
    return {
      email: null,
      premiumSelected: null,
      // premiumOptions: [
      //   {
      //     text: 'Xpert+',
      //     value: {
      //       iid: 'dM9XO8eFBEHpbz4Rn7WG',
      //       aids: [
      //         'zWK0ATiI0qLauIsDHx5O',
      //         'oWUHHaZKlBxKT8pMQPUq',
      //         'xXoQnsR8qaVSJcRTEjPJ',
      //         '96mdfn0BSYxizRAtmx60',
      //         '21cxVRjP2Do7xNkJ8ngi',
      //         'IEfomIqEBQITFb8Xlzqs'],
      //       exids: [
      //         'SQHwk56qMcPhsSOwIGVn',
      //         '781K44YCXJzhqIjMggwS',
      //         'IEMN9sG14YO0L3BXs97K',
      //         'q2FHFaKcehcRy0piRCL2',
      //         '6JondF5FP7sBPUrywmhm',
      //         'qZCk0dK2tXYdr7cJWwWI',],
      //     }
      //   },
      //   {
      //     text: 'Xpert',
      //     value: {
      //       iid: 'czVm9rDTCUcXorMZjcY7',
      //       aids: [
      //         'zWK0ATiI0qLauIsDHx5O',
      //         'oWUHHaZKlBxKT8pMQPUq',
      //         'xXoQnsR8qaVSJcRTEjPJ',
      //         '96mdfn0BSYxizRAtmx60',
      //         '21cxVRjP2Do7xNkJ8ngi',
      //         'IEfomIqEBQITFb8Xlzqs'],
      //       exids: [
      //         'SQHwk56qMcPhsSOwIGVn',
      //         '781K44YCXJzhqIjMggwS',
      //         'IEMN9sG14YO0L3BXs97K',
      //         'q2FHFaKcehcRy0piRCL2',
      //         '6JondF5FP7sBPUrywmhm',
      //         'qZCk0dK2tXYdr7cJWwWI',],
      //     }
      //   },
      //   {
      //     text: 'Basic',
      //     value: {
      //       iid: 'Z9xOPaTZ08zPolsXiMob',
      //       aids: [
      //         'zWK0ATiI0qLauIsDHx5O',
      //         'oWUHHaZKlBxKT8pMQPUq',
      //         'xXoQnsR8qaVSJcRTEjPJ',
      //         '96mdfn0BSYxizRAtmx60',
      //         '21cxVRjP2Do7xNkJ8ngi',
      //         'IEfomIqEBQITFb8Xlzqs'],
      //       exids: [
      //         'SQHwk56qMcPhsSOwIGVn',
      //         '781K44YCXJzhqIjMggwS',
      //         'IEMN9sG14YO0L3BXs97K',
      //         'q2FHFaKcehcRy0piRCL2',
      //         '6JondF5FP7sBPUrywmhm',
      //         'qZCk0dK2tXYdr7cJWwWI',],
      //     }
      //   },
      //   {
      //     text: 'Lite PU',
      //     value: {
      //       iid: '5fiH6MFJuzIh01ZHmhQR',
      //       aids: ['oWUHHaZKlBxKT8pMQPUq'],
      //       exids: ['781K44YCXJzhqIjMggwS',],
      //     }
      //   },
      //   {
      //     text: 'Lite PK',
      //     value: {
      //       iid: 'MqkRgJFQwOMI4vVcR955',
      //       aids: ['xXoQnsR8qaVSJcRTEjPJ'],
      //       exids: ['IEMN9sG14YO0L3BXs97K',],
      //     }
      //   },
      //   {
      //     text: 'Lite PPU',
      //     value: {
      //       iid: 'ccfW8v5Nel22U1hrhrGN',
      //       aids: ['96mdfn0BSYxizRAtmx60'],
      //       exids: ['q2FHFaKcehcRy0piRCL2',],
      //     }
      //   },
      //   {
      //     text: 'Lite PBM',
      //     value: {
      //       iid: 'ML22hop0GSBbFMLyMPbs',
      //       aids: ['21cxVRjP2Do7xNkJ8ngi'],
      //       exids: ['6JondF5FP7sBPUrywmhm',],
      //     }
      //   },
      //   {
      //     text: 'Lite Inggris',
      //     value: {
      //       iid: 'aws4n3PcaP02f91OwjJS',
      //       aids: ['IEfomIqEBQITFb8Xlzqs'],
      //       exids: ['qZCk0dK2tXYdr7cJWwWI',],
      //     }
      //   },
      // ],
    }
  },
  computed: {
    ...mapState([
      'premiumUsers'
    ]),
    premiumUser: function(){
      let v = this
      if(!v.premiumSelected){
        return null
      }
      return v.premiumUsers.find((e)=>
        e.id == v.premiumSelected.iid
      )
    },
    premiumOptions: function(){
      let v = this
      let result = v.premiumUsers.map((premiumUser)=>{
        return {
          text: premiumUser.name,
          value: {
            iid: premiumUser.id,
            aids: premiumUser.aids,
            exids: premiumUser.exids,
          }
        }
      })
      console.log(result)
      return result
    }
  },
  methods: {
    ...mapActions([
      'bindPremiumUsers',
    ]),
    submit(){
      let email = this.email
      registerExamUser(
        email,
        this.premiumSelected.iid,
        this.premiumSelected.aids,
        this.premiumSelected.exids
      ).then(()=>alert(`Sukses Register Premium ${email}`))
    }
  },
  created() {
    this.bindPremiumUsers()
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variables.scss";

*{
  text-align:left
}

.actions {
  max-width: 30rem;
  margin: 0 auto 2rem auto;
}
.export {
  max-width: 30rem;
  margin: 0 auto 2rem auto;
  pre {
    padding: 1rem;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: bold;
    background: rgba($color-black, 0.05);
    color: rgba($color-black, 0.8);
  }
  code {
    display: block;
    white-space: pre-wrap;
  }
}
.scroll {
    max-height: 100px;
    overflow-y: auto;
}
</style>