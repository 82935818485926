/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-console */
import Vue from 'vue'
import Vuex from 'vuex'
import { db, auth } from '@/plugins/firebase'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      email: null,
    },
    loggedin: false,

    exercises: [],
    problems: [],
    premiumUsers: [],

    pid: null,
    ppid: null,
    doc: {
      isExist:{},
      problemPack: { pids:[] },
      problem: null,
      problemSolution: null,
      explanation: null,
      tempExplanation: null,
    },
    editor: {
      problemPack: null,
      problem: null,
      problemSolution: null,
      explanation: { contents:[] },
      tempExplanation: null,
    },
    count: 0,
  },
  getters: {
    
  },
  mutations: {
    ...vuexfireMutations,

    // Auth
    setUser: ( state, payload ) => {
      if(!!auth.currentUser){
        state.loggedin = true
        state.user.email = payload.email
      }
    },
    removeUser: ( state ) => {
      if(!auth.currentUser){
        state.loggedin = false
        state.user.email = null
        state.exercises = []
      }
    },

    // Quiz
    setPid(state, pid){ state.pid = pid },
    setPpid(state, ppid){ state.ppid = ppid },

    setProblemPackDoc(state, doc){ state.doc.problemPack = doc },
    setProblemPack(state, data){ state.editor.problemPack = data },

    setProblemDoc(state, [doc, isExist]){
      state.doc.problem = doc
      state.doc.isExist.problem = isExist
    },
    setProblem(state, data){ state.editor.problem = data },
    setProblemDescription(state, data){ state.editor.problem.description = data },
    setProblemOption(state, [idx, data]){ state.editor.problem.options[idx] = data },
    setProblemArticle(state, data){ state.editor.problem.article = data },
    enableProblemArticle(state, status){ state.editor.problem.hasArticle = status },

    setProblemSolutionDoc(state, [doc, isExist]){
      state.doc.problemSolution = doc
      state.doc.isExist.problemSolution = isExist
    },
    setProblemSolution(state, data){ state.editor.problemSolution = data },

    setTempExplanationDoc(state, data){ state.doc.tempExplanation = data },
    setTempExplanation(state, data){ state.editor.tempExplanation = data },

    setExplanationDoc(state, [doc, isExist]){
      state.editor.explanation = doc
      state.doc.isExist.explanation = isExist
    },
    setExplanation(state, doc){ state.editor.explanation = doc },
    addExplanationContent(state, contentTitle){
      state.editor.explanation.contents.push({
        type: 'steps',
        title: contentTitle,
        isDefault: false,
        steps: [{}]
      })
    },
    removeExplanationContent(state, idx){ state.editor.explanation.contents.splice(idx, 1) },
    setExplanationStepLength(state, [idx, length]){
      let delta = state.editor.explanation.contents[idx].steps.length - length;
      if (delta > 0) { state.editor.explanation.contents[idx].steps.splice(length) }
      else { while (delta++ < 0) { state.editor.explanation.contents[idx].steps.push(null); } }
    },
    setExplanationStep(state, [idx, stepIdx, data]){ state.editor.explanation.contents[idx].steps[stepIdx] = data},
  },
  actions: {
    // Auth
    login: async ( context, payload ) => {
      await auth.signInWithEmailAndPassword(payload.email, payload.password)
        .then(()=>router.push({ path: '/exercise' }))
        .catch(error=>alert(error.code+" : "+error.message))
    },
    logout: async (context) => {
      await auth.signOut()
        .then(()=>{
          context.commit('removeUser')
          context.dispatch('unbindExercises')
          router.push({ path: '/login' })
        })
        .catch(error=>alert(error.code+" : "+error.message))
    },
    autoSignIn (context, payload) {
      context.commit('setUser', {email: payload.email})
      context.dispatch('bindExercises')
    },
    
    // Exercise
    bindPremiumUsers: firestoreAction(( { bindFirestoreRef } ) => {
      // bind exercise Collection
      bindFirestoreRef('premiumUsers', db.collection('sources/analitica/premiumUsers').orderBy('name', 'desc'))
        .catch(error=>alert(error.code+" : "+error.message))
    }),
    
    // Exercise
    bindExercises: firestoreAction(( { bindFirestoreRef } ) => {
      // bind exercise Collection
      bindFirestoreRef('exercises', db.collection('sources/analitica/exercises').orderBy('title','asc'))
        .catch(error=>alert(error.code+" : "+error.message))
    }),
    unbindExercises: firestoreAction(( { unbindFirestoreRef  } ) => {
      // unbind exercise Collection
      unbindFirestoreRef ('documents')
    }),
    setExercises: firestoreAction( (_, exercise) => {
      // create a copy that excludes `id`
      const exerciseData = { ...exercise }
    
      // update exercise Doc
      return db.collection('sources/analitica/exercises')
        .doc(exercise.id)
        .set(exerciseData)
    }),

    // Quiz
    getProblemPack: ( context ) => {
      // ppid
      let ppid = context.state.ppid
      
      // get problem pack
      db.collection('sources/analitica/problemPacks').doc(ppid).get()
      .then(snapshot  => {
        if (!snapshot.exists) {
          alert('No problemPack found!');
        } else {
          context.commit('setProblemPackDoc', snapshot.data())
          context.commit('setProblemPack', snapshot.data())
        }
      })
      .catch(err => {
        alert('Error getting document', err);
        console.log(err);
      });
    },

    getProblem: ( context ) => {
      // get pid
      let pid = context.state.pid
      
      // get problem
      db.collection('sources/analitica/problems').doc(pid).get()
        .then(snapshot  => {
          let data 
          if (!snapshot.exists) {
            // alert('No problem found! Creating blank template.');
            data = {
              hasArtice: false,
              article: {},
              description: {},
              options: []
            }
          } else {
            data = snapshot.data()
          }
          context.commit('setProblemDoc', [data, snapshot.exists])
          context.commit('setProblem', data)
        })
        .catch(err => {
          alert('Error getting document', err);
          console.log(err);
        });
      
      // get solution
      db.collection('sources/analitica/solutions').doc(pid).get()
        .then(snapshot  => {
          let data = {}
          if (!snapshot.exists) {
            // alert('No solution found! Creating blank template.');
            data = { optionCorrect: [] }
          } else {
            data = snapshot.data()
          }
          context.commit('setProblemSolutionDoc', [data, snapshot.exists])
          context.commit('setProblemSolution', data)
        })
        .catch(err => {
          alert('Error getting document', err);
          console.log(err);
        });
      
      // get explanation
      db.collection('sources/analitica/explanations').doc(pid).get()
        .then(snapshot  => {
          let data = {}
          if (!(snapshot.exists)
              || !(snapshot.data().contents)
              || !(snapshot.data().contents[0])
              || !(snapshot.data().contents[0].type == 'single')
              || !(snapshot.data().contents[0].title == 'Pembahasan')
              || !(snapshot.data().contents[0].isDefault)
              || !(snapshot.data().contents[0].steps)
              || !(snapshot.data().contents[0].steps[0])
            ) {
            // alert('No explanation found! Creating blank template.');
            data = { contents: [{
              type: 'single',
              title: 'Pembahasan',
              isDefault: true,
              steps: [{}]
            }] }
          } else {
            data = snapshot.data()
          }
          context.commit('setExplanationDoc', [data, snapshot.exists])
          context.commit('setExplanation', data)
          context.commit('setExplanationDoc', [data, snapshot.exists])
          context.commit('setExplanation', data)

          context.commit('setTempExplanationDoc', data.contents[0].steps[0])
          context.commit('setTempExplanation', data.contents[0].steps[0])
        })
        .catch(err => {
          alert('Error getting document', err);
          console.log(err);
        });
    },

    updateProblem: ( context ) => {
      // get pid
      let pid = context.state.pid
      let problemDoc = context.state.editor.problem

      if(context.state.doc.isExist.problem){
        db.collection('sources/analitica/problems')
          .doc(pid)
          .update(problemDoc)
          .then(()=>{
            context.commit('setProblemDoc', [problemDoc, true])
            alert('Update Problem Success!')
          })
          .catch(err => alert(err))
      } else {
        db.collection('sources/analitica/problems')
          .doc(pid)
          .set(problemDoc)
          .then(()=>{
            context.commit('setProblemDoc', [problemDoc, true])
            alert('Update Problem Success!')
          })
          .catch(err => alert(err))
      }
    },

    updateSolution: ( context ) => {
      // get pid
      let pid = context.state.pid
      let solutionDoc = context.state.editor.problemSolution

      if(context.state.doc.isExist.problemSolution){
        db.collection('sources/analitica/solutions')
          .doc(pid)
          .update(solutionDoc)
          .then(()=>{
            context.commit('setProblemSolutionDoc', [solutionDoc, true])
            alert('Update Solution Success!')
          })
          .catch(err => alert(err))
      } else {
        db.collection('sources/analitica/solutions')
          .doc(pid)
          .set(solutionDoc)
          .then(()=>{
            context.commit('setProblemSolutionDoc', [solutionDoc, true])
            alert('Update Solution Success!')
          })
          .catch(err => alert(err))
      }
    },

    updateExplanation: ( context ) => {
      // get pid
      let pid = context.state.pid
      let explanationDoc = context.state.editor.explanation

      if(context.state.doc.isExist.explanation){
        db.collection('sources/analitica/explanations')
          .doc(pid)
          .update(explanationDoc)
          .then(()=>{
            context.commit('setProblemExplanationDoc', [explanationDoc, true])
            alert('Update Explanation Success!')
          })
          .catch(err => alert(err))
      } else {
        db.collection('sources/analitica/explanations')
          .doc(pid)
          .set(explanationDoc)
          .then(()=>{
            context.commit('setProblemExplanationDoc', [explanationDoc, true])
            alert('Update Explanation Success!')
          })
          .catch(err => alert(err))
      }
    },

    updateTempExplanation: ( context ) => {
      // get pid
      let pid = context.state.pid
      let tempExplanationDoc = context.state.editor.tempExplanation
      let explanationDoc = { contents: [{
        type: 'single',
        title: 'Pembahasan',
        isDefault: true,
        steps: [tempExplanationDoc]
      }] }

      db.collection('sources/analitica/explanations')
      .doc(pid)
      .set(explanationDoc)
      .then(()=>{
        context.commit('setTempExplanationDoc', tempExplanationDoc)
        context.commit('setTempExplanation', tempExplanationDoc)
        alert('Update Explanation Success!')
      })
      .catch(err => alert(err))
    },
  },
  modules: {
  }
})
