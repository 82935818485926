<template>
  <div class="home">
    <b-container>
        <b-card-group columns>
            <b-card v-for="problem in problems" :key="problem.id" @click="onSelectProblem(problem)">
                <b-card-title>{{ problem.tag }}</b-card-title>
                <b-card-text>
                    {{ problem.description }}
                </b-card-text>
                <b-card-text class="small text-muted">Last updated 3 mins ago</b-card-text>
            </b-card>
        </b-card-group>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { db } from '../plugins/firebase'
// @ is an alias to /src
export default {
  name: 'home',
  computed: {
    ...mapState(['credentials', 'loggedin', 'firestore']),
  },
  data() {
    return {
      // problems: [1,2,3]
    }
  },
  firestore: {
    problems: db.collection('sources/analitica/problems').limit(3),
  },
  methods: {
    ...mapActions([
      'login'
    ]),
    ...mapMutations([
      'updateSelectedProblem'
    ]),
    onSelectProblem(problem) {
      this.$router.push({ name: 'problem' })
      this.updateSelectedProblem(problem.id)
    }
  },
  created() {
    // this.login()
  }
}
</script>
