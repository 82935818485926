<template>
  <div id="app">
    <div id="nav">
      <span v-if="!loggedin">
        <router-link to="/login">Login</router-link>
      </span>
      <span v-if="loggedin">
        <router-link to="/exercise" >Exercise</router-link> |
        <!-- <router-link to="/problem" >Problem</router-link> | -->
        <router-link to="/ProblemEdit" >ProblemEdit</router-link> |
        <router-link to="/RegisterPremium" >RegisterPremium</router-link> |
        <span @click="onLogOut()"><b>Logout</b></span>
      </span>
    </div>
    <router-view/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'app',
  computed: {
    ...mapState(['loggedin'])
  },
  methods: {
    ...mapActions([
      'logout'
    ]),
    async onLogOut() {
      await this.logout()
      if(!this.loggedin){
        alert("Logout Success.")
      }
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
